import React from 'react';

import { ScPage } from '../components/page/styled';
import { MainLayout } from '../layouts/main-layout';
import { GetGogeta } from '../sections/common/GetGogeta/GetGogeta';
import { Hero } from '../sections/EmployeeProduct/Hero';
import { Schemas } from '../sections/EmployeeProduct/Schemas';

const EmployeeProduct = () => {
	return (
		<MainLayout
			title="Employee Benefits | Gogeta Salary Sacrifice Products"
			description="Explore Gogeta’s salary sacrifice products, from cycling to childcare. Discover how employees can save on everyday costs with simple, tax-efficient benefits."
		>
			<ScPage>
				<Hero />
				<GetGogeta />
				<Schemas />
			</ScPage>
		</MainLayout>
	);
};

export default EmployeeProduct;
